
<template>
    <component :is="'div'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="questionData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos de la pregunta</h4>
            <div class="alert-body">
                No se encontró ninguna pregunta con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'questions-list'}"
                >
                    Lista de preguntas
                </b-link>
                para buscar otra pregunta.
            </div>
        </b-alert>

        <b-tabs
            v-if="questionData"
            pills
        >
      
            <!-- Tab: General Information -->
            <b-tab>

                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información general</span>
                </template>
                
                <question-edit-tab-general-information class="mt-2 pt-75" :question-data="questionData" />
                
            </b-tab>
      
        </b-tabs>
        
    </component>
</template>

<script>

    import QuestionEditTabGeneralInformation from './general-information/QuestionEditTabGeneralInformation.vue';
    import questionStoreModule from '../questionStoreModule';
    import { ref, onUnmounted } from '@vue/composition-api';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            QuestionEditTabGeneralInformation
        },
        setup() {
            const questionData = ref(null);

            const QUESTION_APP_STORE_MODULE_NAME = 'app-question';

            // REGISTER MODULE
            if (!store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.registerModule(QUESTION_APP_STORE_MODULE_NAME, questionStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTION_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-question/fetchQuestion', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        questionData.value = undefined
                    } else {
                        questionData.value = response.data.question;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        questionData.value = undefined
                    }
                });

            return {
                questionData
            }
        }
    }

</script>